/* eslint-disable indent */
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col } from 'src/components/Col';
import { RangeDatePicker } from 'src/components/datepicker';
import { Filters, FiltersProps } from 'src/components/Filters';
import Layout from 'src/components/layout';
import ExtraHeaders from 'src/components/layout/header/extraHeaders';
import { Row } from 'src/components/Row';
import { AGE_FILTER, GENDER_FILTER } from 'src/constants/filters';
import { IUseParamsCameras } from 'src/interfaces/common.interface';
import { IHeaderData } from 'src/interfaces/store.interface';
import * as ActionTypes from 'src/store/actions';
import { getHoursMinutesAndSeconds, getTimestampOf, today } from 'src/utils/helpers';

import PerformanceGraph from 'src/components/PerformanceGraph/PerformanceGraph';
import CameraHeatmaps from './components/CameraHeatmaps';
import CameraIndicators from './components/CameraIndicators';
import {
  cameraPerformanceCSVDownload,
  cameraPerformancePlotData,
  cameraPerformancePlotLayout,
} from './utils/performance.util';
import CameraTables from './components/CameraTables';

const FILTERS: FiltersProps['filters'] = [GENDER_FILTER, AGE_FILTER];

const Cameras: React.FC = () => {
  const { storeId, departmentId, cameraId } = useParams<IUseParamsCameras>();
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const { genderFilter, ageFilter } = useSelector((state: any) => state.filter);
  const { store, store_loading, storeToday } = useSelector((state: any) => state.stores);
  const {
    interval,
    allCameraGraphs: { all_customers_avg, graph_data: camGraphData, day_over_day_data: camGraphDoDData } = [],
    allCameraGraphsLoading,
  } = useSelector((state: any) => state.graphs);

  const { cameraStats, cameraStatsToday } = useSelector((state: any) => state.cameras);
  // const { outsideTraffic } = useSelector((state: any) => state.areas);

  const [headerData, setHeaderData] = useState<IHeaderData>({
    address: '',
    footFall: '',
    location: '',
    timeSpent: '',
    avgTime: '',
  });
  // const [_outsideTrafficData, setOutsideTrafficData] = useState([]);

  const startTimeTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
  const endTimeTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));
  const intervalLabel = `${moment(startDate).format('DD/MM/YYYY HH:mm')} - ${moment(endDate).format(
    'DD/MM/YYYY HH:mm',
  )}`;

  // useEffect(() => {
  //   if (!outsideTraffic.length) return;
  //   const mappedData = outsideTraffic?.map((traffic: any, i: number) => ({
  //     key: i,
  //     id: traffic.id,
  //     areas: traffic.name,
  //     totalFootfall: traffic.footfall_metrics,
  //     avgTime: getHoursMinutesAndSeconds(traffic.time_metrics?.average_time),
  //     timeSpent: getHoursMinutesAndSeconds(traffic.time_metrics?.total_time),
  //   }));
  //   setOutsideTrafficData(mappedData);
  // }, [outsideTraffic]);

  const fetchDwellAnalysisData = (seconds = '') => {
    dispatch(
      ActionTypes.getDwellAnalysis.request({
        storeId,
        departmentId,
        cameraId,
        areaType: 'DA',
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
        thresholdFilter: seconds,
      }),
    );
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (storeId && storeId != store.id) {
      dispatch(
        ActionTypes.getStore.request({
          storeId,
          startDate: Math.trunc(getTimestampOf(new Date(startDate))),
          endDate: Math.trunc(getTimestampOf(new Date(endDate))),
          genderFilter: genderFilter || undefined,
          ageFilter: ageFilter || undefined,
        }),
      );
    }

    if (cameraId) {
      dispatch(ActionTypes.getCameraImage.request({ cameraId }));
    }

    if (storeId && departmentId && cameraId) {
      dispatch(
        ActionTypes.getCameraStatsToday.request({
          cameraId,
        }),
      );
      dispatch(
        ActionTypes.getCameraStats.request({
          cameraId,
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
          genderFilter: genderFilter || undefined,
          ageFilter: ageFilter || undefined,
        }),
      );
      // dispatch(
      //   ActionTypes.getCamerasWithInfo.request({
      //     storeId,
      //     startDate: startTimeTimestamp,
      //     endDate: endTimeTimestamp,
      //   }),
      // );

      fetchDwellAnalysisData();

      // dispatch(
      //   ActionTypes.getOutsideTraffic.request({
      //     storeId,
      //     departmentId,
      //     cameraId,
      //     startDate: startTimeTimestamp,
      //     endDate: endTimeTimestamp,
      //   }),
      // );

      // dispatch(
      //   ActionTypes.getAreasOfInterest.request({
      //     storeId,
      //     departmentId,
      //     cameraId,
      //     startDate: startTimeTimestamp,
      //     endDate: endTimeTimestamp,
      //   }),
      // );
      dispatch(
        ActionTypes.getWeekCameraGraphs.request({
          // bug
          storeId,
          departmentId,
          cameraId,
          startDate: startTimeTimestamp, // Math.trunc(getTimestampOf(new Date(lastWeekTime))),
          endDate: today / 1000,
          interval: '1d',
        }),
      );
      dispatch(
        ActionTypes.getCameraAreas.request({
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
          storeId,
          cameraId,
          areaType: 'ALL',
          genderFilter: genderFilter || undefined,
          ageFilter: ageFilter || undefined,
        }),
      );

      dispatch(
        ActionTypes.getCameraAreasOfInterest.request({
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
          storeId,
          cameraId,
          genderFilter: genderFilter || undefined,
          ageFilter: ageFilter || undefined,
        }),
      );
    }
    dispatch(
      ActionTypes.getHeatmap.request({
        cameraId,
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
  }, [dispatch, storeId, departmentId, cameraId, startTimeTimestamp, endTimeTimestamp, genderFilter, ageFilter]);

  useEffect(() => {
    dispatch(
      ActionTypes.getAllCameraGraphs.request({
        storeId,
        departmentId,
        cameraId,
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        interval,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
  }, [
    dispatch,
    storeId,
    departmentId,
    cameraId,
    startTimeTimestamp,
    endTimeTimestamp,
    genderFilter,
    ageFilter,
    interval,
  ]);

  const fetchCameraPaths = useCallback(() => {
    dispatch(
      ActionTypes.getCameraPaths.request({
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        cameraId,
      }),
    );
  }, [dispatch, cameraId, startTimeTimestamp, endTimeTimestamp]);

  // useEffect(() => {
  //   dispatch(
  //     ActionTypes.getCameraPaths.request({
  //       startDate: startTimeTimestamp,
  //       endDate: endTimeTimestamp,
  //       cameraId,
  //     }),
  //   );
  // }, [dispatch, cameraId, startTimeTimestamp, endTimeTimestamp]);

  useEffect(() => {
    const timeSpent =
      (cameraStatsToday.time_metrics?.total_time &&
        getHoursMinutesAndSeconds(cameraStatsToday.time_metrics.total_time)) ||
      0;

    const averageTimeOnStoreToday = getHoursMinutesAndSeconds(storeToday?.indoor_time_metrics?.average_time) || 0;

    const cameraData =
      store && store.cameras
        ? store.cameras
            .filter((camera: any) => {
              return camera.id === parseInt(cameraId || '', 10);
            })
            .shift()
        : null;
    const cameraName = cameraData ? ` - ${cameraData.es_cam_id}` : '';

    setHeaderData({
      address: `${store.address_1} ${store.address_2}`,
      footFall: cameraStatsToday.store_indoor_footfall_metrics,
      location: `${store.name}${cameraName}`,
      avgTime: String(averageTimeOnStoreToday),
      timeSpent,
    });
  }, [store, cameraId, cameraStats, cameraStatsToday, camGraphData, camGraphDoDData, all_customers_avg]);

  const SectionFilters = (
    <Row>
      <Col className="flex flex-row gap-5">
        <RangeDatePicker needTime />

        <Filters
          title="Filters"
          filters={FILTERS}
          onApplyFilters={({ age, gender }) => {
            const ageFilter = age ? age.join(',') : undefined;
            const genderFilter = gender ? gender.join(',') : undefined;
            dispatch(ActionTypes.updateAge(ageFilter));
            dispatch(ActionTypes.updateGender(genderFilter));
          }}
          onClear={() => {
            dispatch(ActionTypes.updateAge(''));
            dispatch(ActionTypes.updateGender(''));
          }}
        />
      </Col>
    </Row>
  );

  return (
    <Layout
      title={headerData.location}
      subtitle={headerData.address}
      extraHeader={<ExtraHeaders footFall={headerData.footFall} avgTime={headerData.avgTime} />}
      loadingHeader={store_loading}
    >
      <div className="flex flex-col gap-2">
        {SectionFilters}
        <CameraIndicators />
        <PerformanceGraph
          intervalLabel={intervalLabel}
          performanceDataCSV={cameraPerformanceCSVDownload}
          performancePlotData={cameraPerformancePlotData}
          performancePlotLayout={cameraPerformancePlotLayout}
          type="camera"
          loading={allCameraGraphsLoading || store_loading}
        />
        <CameraHeatmaps fetchCameraPaths={fetchCameraPaths} cameraId={cameraId} startTimeTimestamp={startTimeTimestamp} endTimeTimestamp={endTimeTimestamp} />
        <CameraTables intervalLabel={intervalLabel} fetchDwellAnalysisData={fetchDwellAnalysisData} />
      </div>
    </Layout>
  );
};

export default Cameras;
