/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-plusplus */
import { isEmpty, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiFilterOffLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { PopUp } from 'src/components/PopUp';
import { Row } from 'src/components/Row';
import { Table } from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useModal } from 'src/hooks/useModal';
import { Lock } from 'src/components/Lock';
import {
  convertSecondsToHMS,
  getHoursMinutesAndSeconds,
  getHoursMinutesAndSecondsShorthandForAreaOfInterestTable,
} from 'src/utils/helpers';
import CameraPopUpAnalysisContent from '../CameraPopUpAnalysisContent';

type ThresholdSecondsProps = {
  seconds: string;
};

type CameraTableProps = {
  intervalLabel: string;
  fetchDwellAnalysisData: (thresholdSeconds: string) => void;
};

const CameraTables: React.FC<CameraTableProps> = ({ intervalLabel, fetchDwellAnalysisData }) => {
  const {
    register,
    setValue,
    handleSubmit: handleSubmitThresholdSeconds,
    getValues,
  } = useForm<ThresholdSecondsProps>({});

  const { dwellAnalysis } = useSelector((state: any) => state.areas);
  const { areasOfInterest, areasOfInterestLoading } = useSelector((state: any) => state.areasOfInterest);

  const [thresholdSeconds, setThresholdSeconds] = useState(''); // threshold for dwell analysis
  const [dwellAnalysisData, setDwellAnalysisData] = useState([]);
  const [areasOfInterestData, setAreasOfInterestData] = useState([]);
  const [areasOfInterestTableData, setAreasOfInterestTableData] = useState<any>([]);
  const [areasOfInterestTableDataTotal, setAreasOfInterestTableDataTotal] = useState<any>([]);

  const [isOpenPopUpAnalysis, onClosePopUpAnalysis, onOpenPopUpAnalysis] = useModal();

  useEffect(() => {
    if (!areasOfInterest?.length) {
      setAreasOfInterestData([]);
      return;
    }

    const newAreasOfInterestTableData = areasOfInterest.map((area: any) => ({
      key: area.id,
      id: area.id,
      areas: area.name,
      noOfViews: area.footfall_metrics,
      avgTime: convertSecondsToHMS(area.time_metrics?.average_time),
    }));

    setAreasOfInterestData(newAreasOfInterestTableData);
  }, [areasOfInterest]);

  useEffect(() => {
    if (!dwellAnalysis.length) {
      setDwellAnalysisData([]);
      return;
    }

    const mappedData = dwellAnalysis
      ? dwellAnalysis.map((dwell: any, i: number) => ({
        key: i,
        id: dwell.id,
        areas: dwell.name,
        noOfViews: dwell.footfall_metrics,
        avgTime: convertSecondsToHMS(dwell.time_metrics?.average_time),
      }))
      : [];
    setDwellAnalysisData(mappedData);
  }, [dwellAnalysis]);

  useEffect(() => {
    if (!areasOfInterest.length) {
      // If there are no areasOfInterest, clears table data to avoid showing data from other camera.
      setAreasOfInterestTableData([]);
      return;
    }
    const newAreasOfInterestTableData = [];
    let totalPeople = 0;
    let totalTimeSpent = 0;

    for (let i = 0; i < areasOfInterest.length; i++) {
      // Canary Wharf specific filter:
      if (areasOfInterest[i].camera.store === 4) {
        const areaOfInterest = areasOfInterest[i];
        // Show only first area
        const areaName = areaOfInterest.people_and_timings.length > 0 ? areaOfInterest.people_and_timings?.[0].name : '';
        const filteredByTopEntrance = areaOfInterest.people_and_timings.filter((area: {name: string}) => area.name === areaName);
        const unique = uniqBy(filteredByTopEntrance, 'time_spent') as unknown as {
          'name': string,
          'entry_date': string,
          'exit_date': string,
          'entry_time': number,
          'exit_time': number,
          'time_spent': number
        }[];
        totalPeople = unique.length;

        for (let i = 0; i < totalPeople; i++) {
          const person = unique[i];
          totalTimeSpent += person.time_spent;
          newAreasOfInterestTableData.push({
            area: `${person.name}`,
            entry_date: person.entry_date,
            exit_date: person.exit_date,
            time_spent: getHoursMinutesAndSeconds(person.time_spent),
          });
        }
      } else {
        const areaOfInterest = areasOfInterest[i];
        totalPeople = areaOfInterest.people_and_timings.length;
        for (let i = 0; i < totalPeople; i++) {
          const person = areaOfInterest.people_and_timings[i];
          totalTimeSpent += person.time_spent;
          newAreasOfInterestTableData.push({
            area: `${areaOfInterest.name}: ${person.name}`,
            entry_date: person.entry_date,
            exit_date: person.exit_date,
            time_spent: getHoursMinutesAndSeconds(person.time_spent),
          });
        }
      }
    }

    setAreasOfInterestTableData(newAreasOfInterestTableData);
    if (newAreasOfInterestTableData.length > 0) {
      const temp1 = getHoursMinutesAndSecondsShorthandForAreaOfInterestTable(totalTimeSpent / totalPeople);
      const temp2 = getHoursMinutesAndSecondsShorthandForAreaOfInterestTable(totalTimeSpent);
      const temp = ['Total', `${totalPeople} people`, `${temp1} (Avg. Time)`, `${temp2} (Total time)`];
      setAreasOfInterestTableDataTotal(temp);
    }
  }, [areasOfInterest]);

  const clearAllThresholdValues = () => {
    setValue('seconds', '');
    setThresholdSeconds('');
  };

  if (areasOfInterestLoading) {
    return (
      <div className="animate-pulse flex flex-row gap-3 my-3">
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="animate-bounce h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
        </div>
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
        </div>
      </div>
    );
  }

  const AreasOfInterestAvg = (
    <Card
      title="Areas of Interest - Average"
      subTitle="Area Activities"
      iconsGroup={
        areasOfInterestData.length > 0 && (
          <div className="flex gap-4">
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              <DownloadCSV
                filename={`Areas of Interest - ${intervalLabel}`}
                headers={[
                  { label: 'Areas', key: 'areas' },
                  { label: 'Enty Time', key: 'noOfViews' },
                  { label: 'Avg. time', key: 'avgTime' },
                ]}
                data={areasOfInterestData}
              >
                <Icon icon="download" className="text-xl" />
              </DownloadCSV>
            </Tooltip>
          </div>
        )
      }
    >
      <div className="p-6">
        <Lock isLocked={false}>
          {areasOfInterestData.length === 0 && (
            <div className="text-center text-neutral-600">
              <span>No data available</span>
            </div>
          )}
          {areasOfInterestData.length > 0 && (
            <Table
              cols={[
                { title: 'Areas', field: 'areas', order: true },
                { title: 'No. of views', field: 'noOfViews', order: true },
                { title: 'Avg. time', field: 'avgTime', order: true },
              ]}
              rows={areasOfInterestData}
            />
          )}
        </Lock>
      </div>
    </Card>
  );

  const AreasOfInterestDetailed = (
    <Card
      title="Areas of Interest"
      subTitle="Time Tracking of People Activities"
      iconsGroup={
        areasOfInterestData.length > 0 && (
          <div className="flex gap-4">
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              <DownloadCSV
                filename={`Areas of Interest - ${intervalLabel}`}
                headers={[
                  { label: 'Area', key: 'area' },
                  { label: 'Entry Time', key: 'entry_date' },
                  { label: 'Exit time', key: 'exit_date' },
                  { label: 'Time Spent', key: 'time_spent' },
                ]}
                data={areasOfInterestTableData}
              >
                <Icon icon="download" className="text-xl" />
              </DownloadCSV>
            </Tooltip>
          </div>
        )
      }
    >
      <div className="p-6">
        <Lock isLocked={false}>
          {areasOfInterestTableData.length === 0 && (
            <div className="text-center text-neutral-600">
              <span>No data available</span>
            </div>
          )}
          {areasOfInterestTableData.length > 0 && (
            <Table
              className="block overflow-auto max-h-60"
              // bodyClassName=""
              cols={[
                { title: 'Area', field: 'area', order: true },
                { title: 'Entry Time', field: 'entry_date', order: true },
                { title: 'Exit time', field: 'exit_date', order: true },
                { title: 'Time Spent', field: 'time_spent', order: true },
              ]}
              rows={areasOfInterestTableData}
              dataTotal={areasOfInterestTableData && areasOfInterestTableDataTotal ? areasOfInterestTableDataTotal : []}
            />
          )}
        </Lock>
      </div>
    </Card>
  );

  return (
    <Row>
      <Col size="6">
        <Card
          title="Dwell Analysis"
          subTitle={`Monitor how many people are in an area at any time. ${
            thresholdSeconds ? `Threshold: ${thresholdSeconds} seconds.` : ''
          }`}
          iconsGroup={
            dwellAnalysisData.length > 0 && (
              <div className="flex gap-4">
                <Tooltip tooltipText="Download" tooltipPlacement="top">
                  <DownloadCSV
                    filename={`Dwell Analysis - ${intervalLabel}`}
                    headers={[
                      { label: 'Areas', key: 'areas' },
                      { label: 'No. of views', key: 'noOfViews' },
                      { label: 'Avg. time', key: 'avgTime' },
                    ]}
                    data={dwellAnalysisData}
                  >
                    <Icon icon="download" className="text-xl" />
                  </DownloadCSV>
                </Tooltip>
                {!isEmpty(thresholdSeconds) && (
                  <Tooltip tooltipText="Clear threshold" tooltipPlacement="top">
                    <RiFilterOffLine className="text-neutral-400" onClick={() => clearAllThresholdValues()} />
                  </Tooltip>
                )}
                <PopUp
                  isOpen={isOpenPopUpAnalysis}
                  onClose={onClosePopUpAnalysis}
                  placement="top-end"
                  element={(
                    <CameraPopUpAnalysisContent
                      thresholdSeconds={thresholdSeconds}
                      setThresholdSeconds={setThresholdSeconds}
                      onClosePopUpAnalysis={onClosePopUpAnalysis}
                      register={register}
                      handleSubmitThresholdSeconds={handleSubmitThresholdSeconds}
                      setValue={setValue}
                      fetchDwellAnalysisData={fetchDwellAnalysisData}
                      getValues={getValues}
                    />
                  )}
                >
                  <Icon onClick={onOpenPopUpAnalysis} tag="button" icon="settings" />
                </PopUp>
              </div>
            )
          }
        >
          <div className="p-6">
            {dwellAnalysisData.length === 0 && (
              <div className="text-center text-neutral-600">
                <span>No data available</span>
              </div>
            )}
            {dwellAnalysisData.length > 0 && (
              <Table
                cols={[
                  { title: 'Areas', field: 'areas', order: true },
                  { title: 'No. of views', field: 'noOfViews', order: true },
                  { title: 'Avg. time', field: 'avgTime', order: true },
                ]}
                rows={dwellAnalysisData}
              />
            )}
          </div>
        </Card>
      </Col>
      <Col size="6">{AreasOfInterestDetailed}</Col>
    </Row>
  );
};

export default CameraTables;
